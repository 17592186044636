import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import apiService from '../services/apiServices';
import '../assets/scss/login.css';
import { withRouter } from '../hooks/withRouter';


class RequestAccess extends Component {
    constructor(props) {
        super(props);
        const email = this.props.location?.state?.email || '';
        console.log(this.props.location)
        this.state = {
            email: email,
            reason: ''
        };
        this.toast = React.createRef();
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { email, reason } = this.state;
        

        try {
            await apiService.post('/users/request-access', { email, reason });
            this.toast.current.show({
                severity: 'success',
                summary: 'Request Submitted',
                detail: 'Your access request has been submitted successfully.',
                life: 3000
            });
            this.setState({ reason: '' });
        } catch (error) {
            this.toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to submit request',
                life: 3000
            });
        }
    }

    handleReasonChange = (event) => {
        this.setState({ reason: event.target.value });
    }

    render() {
        const { email, reason } = this.state;
        return (
            <div className="login-container">
                <div className="login-form">
                    <h2>Request Access</h2>
                    <div className="form-group">
                        <label>Email requesting access</label>
                        <p>{email}</p>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="reason">Reason for Access</label>
                            <InputTextarea id="reason" value={reason} onChange={this.handleReasonChange} rows={5} autoResize className="textarea-full-width" />
                        </div>
                        <Button type="submit" label="Submit" className="login-button" />
                    </form>
                </div>
                <Toast ref={this.toast} />
            </div>
        );
    }
}

export default withRouter(RequestAccess);
