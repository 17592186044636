import React, { Component } from "react";
import apiService from "../../services/apiServices";
import "../../assets/scss/dashboard.css";
import { ProgressSpinner } from "primereact/progressspinner";

class KPIOverviewCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kpis: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const kpis = await apiService.get("/kpis");
      this.setState({ kpis, isLoading: false });
    } catch (error) {
      console.error("Error fetching data: ", error);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { kpis, isLoading } = this.state;

    // Calculate KPIs status counts
    const inProgressKPIs = kpis.filter(
      (kpi) => kpi.status === "In Progress"
    ).length;
    const completedKPIs = kpis.filter(
      (kpi) => kpi.status === "Completed"
    ).length;
    const notStartedKPIs = kpis.filter(
      (kpi) => kpi.status === "Not Started"
    ).length;

    return (
      <>
        {isLoading ? (
          <div
            className="p-d-flex p-jc-center p-ai-center"
            style={{ height: "100px" }}
          >
            <ProgressSpinner />
          </div>
        ) : (
          <div className="kpi-overview">
            <div className="kpi-card kpi-not-started">
              <div className="card-body">
                <h1 className="card-text text-white">{notStartedKPIs}</h1>
                <h6 className="card-title text-white">Not Started</h6>
              </div>
            </div>
            <div className="kpi-card kpi-in-progress">
              <div className="card-body">
                <h1 className="card-text text-white">{inProgressKPIs}</h1>
                <h5 className="card-title text-white">In Progress</h5>
              </div>
            </div>
            <div className="kpi-card kpi-completed">
              <div className="card-body">
                <h1 className="card-text text-white">{completedKPIs}</h1>
                <h6 className="card-title text-white">Completed</h6>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default KPIOverviewCard;
