// TopicList.js
import React, { Component } from "react";
import { Accordion, Spinner, Modal } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PrimeButton } from "primereact/button";
import apiService from "../../../services/apiServices";
import AddEditTopicForm from "./AddEditTopicForm";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";

class TopicList extends Component {
  state = {
    topicsByDimension: {},
    showModal: false,
    editTopic: null,
    isLoading: true,
  };

  componentDidMount() {
    this.fetchTopicsByDimension();
  }

  fetchTopicsByDimension = async () => {
    try {
      const topics = await apiService.get("/topics");
      const topicsByDimension = {};
      topics.forEach((topic) => {
        const dimensionName = topic.dimension.name;
        if (!topicsByDimension[dimensionName]) {
          topicsByDimension[dimensionName] = [];
        }
        topicsByDimension[dimensionName].push(topic);
      });
      this.setState({ topicsByDimension, isLoading: false });
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  handleDeleteTopic = async (id) => {
    try {
      await apiService.delete(`/topics/${id}`);
      this.fetchTopicsByDimension();
    } catch (error) {
      console.error("Error deleting topic:", error);
    }
  };

  handleShowModal = (editTopic = null) => {
    this.setState({ showModal: true, editTopic });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, editTopic: null });
    this.fetchTopicsByDimension();
  };

  actionBodyTemplate = (rowData) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimeButton
            icon="pi pi-pencil"
            className="p-button-rounded p-button-primary m-2"
            onClick={() => this.handleShowModal(rowData)}
          />
          <PrimeButton
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger m-2"
            onClick={() => this.handleDeleteTopic(rowData._id)}
          />
        </div>
      </>
    );
  };

  render() {
    const { topicsByDimension, showModal, editTopic, isLoading } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-10"></div>
          <div className="col-2">
            <PrimeButton
              variant="success"
              onClick={() => this.handleShowModal()}
              style={{ marginBottom: "10px" }}
            >
              Add New Topic
            </PrimeButton>
          </div>
        </div>

        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <Accordion defaultActiveKey="0">
            {Object.entries(topicsByDimension).map(
              ([dimension, topics], index) => (
                <Accordion.Item eventKey={String(index)} key={dimension}>
                  <Accordion.Header>{dimension}</Accordion.Header>
                  <Accordion.Body>
                    <DataTable
                      stripedRows
                      value={topics}
                      responsiveLayout="scroll"
                    >
                      <Column field="name" header="Name" sortable></Column>
                      <Column
                        body={this.actionBodyTemplate}
                        headerStyle={{ width: "5rem", textAlign: "center" }}
                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                      />
                    </DataTable>
                  </Accordion.Body>
                </Accordion.Item>
              )
            )}
          </Accordion>
        )}
        <Modal show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{editTopic ? "Edit Topic" : "Add Topic"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditTopicForm
              topic={editTopic}
              onCancel={this.handleCloseModal}
              onSuccess={() => {
                this.handleCloseModal();
                this.fetchTopicsByDimension();
              }}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default TopicList;
