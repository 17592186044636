import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from "react";
import { withCookies } from 'react-cookie';

import apiService from "../../services/apiServices";

const AuthContext = createContext();

export const AuthProvider = withCookies(({ children, cookies }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthentication = useCallback(async () => {
    setIsLoading(true);
    const token = cookies.get("authToken");
    if (token) {
      try {
        const response = await apiService.get("/users/me", {
          headers: { Authorization: `Token ${token}` } // Ensure the use of Bearer token if it's the format required
        });
        if (response) {
          setIsAuthenticated(true);
          setUser(response);
        } else {
          throw new Error('Failed to authenticate with token');
        }
      } catch (error) {
        console.error("Authentication error:", error);
        setIsAuthenticated(false);
        setUser(null);
      }
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
    setIsLoading(false);
  }, [cookies]);

  // Listen to potential SSO authentication state changes
  useEffect(() => {
    // Add an event listener to SSO login events, adjust as necessary
    const handleSSOLogin = async (event) => {
      const { token } = event.detail;  // Example payload, adjust based on actual event details
      if (token) {
        cookies.set("authToken", token, {
          path: '/',
          httpOnly: false,
          secure: process.env.NODE_ENV === "production",
          sameSite: "lax"
        });
        await checkAuthentication();
      }
    };

    window.addEventListener('SSOLogin', handleSSOLogin);

    // Perform initial authentication check
    checkAuthentication();

    return () => {
      window.removeEventListener('SSOLogin', handleSSOLogin);
    };
  }, [checkAuthentication, cookies]);

  return useMemo(
    () => (
      <AuthContext.Provider value={{ user, isAuthenticated, isLoading, checkAuthentication }}>
        {!isLoading && children}
      </AuthContext.Provider>
    ),
    [user, isAuthenticated, isLoading, checkAuthentication, children]
  );
});

export const useAuth = () => useContext(AuthContext);
export default withCookies(AuthProvider);
export { AuthContext };
