import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import DimensionList from '../components/settings/dimensions/ListDimensions';
import TopicList from '../components/settings/topics/TopicList';
import UserList from '../components/settings/users/UsersList';
import Layout from '../components/layouts/layout';
import LogsComponent from '../components/logs/logs';
import '../assets/scss/settings.css';

class Settings extends Component {
  render() {
    return (
      <Layout>
        <div className='container' style={{background:'transparent'}}>
          <h1>Settings</h1>
          <TabView scrollable>
            <TabPanel header="Dimensions" headerClassName="flex align-items-center" style={{background:'transparent'}}>
              <DimensionList />
            </TabPanel>
            <TabPanel header="Topics" style={{background:'transparent'}}>
              <TopicList />
            </TabPanel>
            <TabPanel header="Users" style={{background:'transparent'}}>
              <UserList />
            </TabPanel>
            <TabPanel header="Logs" style={{background:'transparent'}}>
              <LogsComponent />
            </TabPanel>
          </TabView>
        </div>
      </Layout>
    );
  }
}

export default Settings;
