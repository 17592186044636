import React, { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import apiService from "../../services/apiServices";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import AddKPIComponent from "./AddKPI";
import "../../assets/scss/data.css";
import { ProgressSpinner } from "primereact/progressspinner";

const statusOptions = [
  { label: "Not Started", value: "Not Started" },
  { label: "In Progress", value: "In Progress" },
  { label: "Completed", value: "Completed" },
];
const getUserNameById = (userId) => {
  const user =  apiService.get(`/users/user/${userId}`);
  console.log(user)
  return user ? user.firstname + " " + user.lastname : "NA";
    
};
class DimensionTabsComponent extends Component {
  constructor(props) {
    super(props);
    this.toast = React.createRef();
    this.state = {
      modifiedBy: '',
      data: [],
      isModified: false,
      changes: {},
      isAddDialogVisible: false,
      uniqueDimensions: [],
      newKpiData: {
        dimensionId: "",
        topicId: "",
        country: "",
        status: "",
        comment: "",
      },
    };
  }

  openAddDialog = () => {
    this.setState({ isAddDialogVisible: true });
  };

  closeAddDialog = () => {
    this.setState({ isAddDialogVisible: false });
  };
  componentDidMount() {
    this.fetchData();
  }



  fetchData = async () => {
    try {
      const user = await apiService.get("/users/me");
      console.log(user);
      if (!user) return console.log("User not found");
      let rawData = [];
      if (user.role !== "admin") {
        rawData = await apiService.get("/kpis/get-specific-kpis");
      } else {
        rawData = await apiService.get("/kpis");
      }
      let structuredData = [];
      console.log(rawData);

      rawData.forEach((cur) => {
        // Find or initialize dimension
        let dimension = structuredData.find((d) => d.id === cur.dimension._id);
        if (!dimension) {
          dimension = {
            id: cur.dimension._id,
            name: cur.dimension.name,
            topics: [],
          };
          structuredData.push(dimension);
        }

        // Find or initialize topic within dimension
        let topic = dimension.topics.find((t) => t.id === cur.topic._id);
        if (!topic) {
          topic = {
            id: cur.topic._id,
            name: cur.topic.name,
            kpis: [],
          };
          dimension.topics.push(topic);
        }

        let modifiedByName = null;
        if (!cur.modifiedBy) modifiedByName = "NA";
        else modifiedByName = getUserNameById(cur.modifiedBy);
        // Add KPI to the topic
        topic.kpis.push({
          id: cur._id,
          country: cur.country,
          comment: cur.comment,
          status: cur.status,
          lastUpdated: cur.updatedAt,
          modifiedBy: modifiedByName,
        });
      });

      // Create a list of unique dimensions for the Accordion tabs
      const uniqueDimensions = structuredData.map((dimension) => ({
        id: dimension.id,
        name: dimension.name,
      }));

      console.log(structuredData);

      this.setState({
        data: structuredData,
        uniqueDimensions,
      });
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  handleChange = (topicId, country, field, value, kpiId) => {
    this.setState((prevState) => {
      // Update the displayed value (immediate state for rendering)
      const dataUpdated = prevState.data.map((dimension) => ({
        ...dimension,
        topics: dimension.topics.map((topic) => {
          if (topic.id === topicId) {
            return {
              ...topic,
              kpis: topic.kpis.map((kpi) => {
                if (kpi.id === kpiId) {
                  // Assuming kpi.id is the unique identifier
                  return { ...kpi, [field]: value }; // Update the specific field
                }
                return kpi;
              }),
            };
          }
          return topic;
        }),
      }));

      // Track changes for saving
      const changesKey = `${topicId}-${kpiId}`; // Simplified key based on topicId and kpiId
      const changesUpdated = {
        ...prevState.changes,
        [changesKey]: {
          ...prevState.changes[changesKey],
          [field]: value,
          id: kpiId, // Store the KPI ID for reference when saving
          country, // Optionally store other identifiers if needed for context or validation
        },
      };

      return {
        data: dataUpdated,
        changes: changesUpdated,
        isModified: true,
      };
    });
  };

  handleSave = async () => {
    const savePromises = Object.keys(this.state.changes).map((key) => {
      const change = this.state.changes[key];
      console.log(change);
      const kpiId = change.id;
      const url = `/kpis/${kpiId}`;
      const data = {
        status: change.status,
        comment: change.comment,
      };

      return apiService.put(url, data);
    });

    try {
      await Promise.all(savePromises);

      this.toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "All changes saved successfully.",
        life: 3000,
      });

      this.setState({
        changes: {},
        isModified: false,
      });

      // Optionally refetch data
      await this.fetchData();
    } catch (error) {
      console.error("Error saving changes:", error);
      this.toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error saving changes: " + error.message,
        life: 3000,
      });
    }
  };

  renderForms = (topic) => {
    return (
      <div>
        {topic.kpis.map((kpi, index) => (
          <React.Fragment key={`${topic.id}-${kpi.country}`}>
            <div className=" row align-items-center p-grid p-align-center p-nogutter">
              <div className="col-2">{kpi.country}</div>
              <div className="col-2">
                <Dropdown
                  value={
                    this.state.changes[`${topic.id}-${kpi.country}`]?.status ||
                    kpi.status
                  }
                  options={statusOptions}
                  onChange={(e) =>
                    this.handleChange(
                      topic.id,
                      kpi.country,
                      "status",
                      e.value,
                      kpi.id
                    )
                  }
                  placeholder="Select a Status"
                />
              </div>
              <div className="col-8">
                <InputTextarea
                  rows={3}
                  cols={30}
                  style={{ width: "100%" }}
                  value={
                    this.state.changes[`${topic.id}-${kpi.country}`]?.comment ||
                    kpi.comment
                  }
                  onChange={(e) =>
                    this.handleChange(
                      topic.id,
                      kpi.country,
                      "comment",
                      e.target.value,
                      kpi.id
                    )
                  }
                  autoResize
                />
                <div className="row align-items-center p-grid p-align-center p-nogutter">
                  <div className="col-6 text-right">
                    <small>
                      Last Updated:{" "}
                      {kpi.lastUpdated
                        ? new Date(kpi.lastUpdated).toLocaleString()
                        : "N/A"}
                    </small>
                  </div>
                  {/* <div className="col-6">
                    <small>Updated By: {kpi.modifiedBy || "N/A"}</small>
                  </div> */}
                </div>
              </div>
            </div>

            {index < topic.kpis.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </div>
    );
  };

  renderTopicTabs = (dimensionId) => {
    const dimension = this.state.data.find((d) => d.id === dimensionId);
    if (!dimension) {
      console.log(`Dimension with id ${dimensionId} not found.`);
      return null;
    }

    return (
      <TabView scrollable>
        {dimension.topics.map((topic, index) => (
          <TabPanel key={`${topic.id}-${index}`} header={topic.name}>
            {this.renderForms(topic)}
          </TabPanel>
        ))}
      </TabView>
    );
  };

  render() {
    const { uniqueDimensions, data } = this.state;
    console.log(uniqueDimensions);
    return (
      <>
        <div>
          <Button
            label="Add KPI"
            icon="pi pi-add"
            className="m-3 cbtn"
            onClick={this.openAddDialog}
          />
          <Button
            label="Save"
            icon="pi pi-save"
            onClick={this.handleSave}
            disabled={!this.state.isModified}
            className="m-3 cbtn"
          />
          {data.length === 0 ? (
            <div
              className="p-d-flex p-jc-center p-ai-center"
              style={{ height: "200px" }}
            >
              <ProgressSpinner />
            </div>
          ) : (
            <Accordion multiple>
              {uniqueDimensions.map((dimension) => (
                <AccordionTab key={dimension.name} header={dimension.name}>
                  {this.renderTopicTabs(dimension.id)}
                </AccordionTab>
              ))}
            </Accordion>
          )}
          <Toast ref={this.toast} />
        </div>
        <div>
          <Dialog
            header="Add KPI"
            visible={this.state.isAddDialogVisible}
            onHide={this.closeAddDialog}
          >
            <AddKPIComponent />
          </Dialog>
        </div>
      </>
    );
  }
}

export default DimensionTabsComponent;
