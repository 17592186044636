import React, { Component } from "react";
import apiService from "../services/apiServices"; 
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Layout from "../components/layouts/layout";
import '../assets/scss/Profile.css';

class UserProfile extends Component {
  state = {
    user: {
      firstname: "",
      lastname: "",
      email: "", // Considered read-only in this context
      units: [], // Will store unit names
    },
    unitsData: [], // To store mapping of unit IDs to names
    editMode: false,
  };

  async componentDidMount() {
    await this.fetchUnits(); // Fetch units to get names
    await this.fetchUserData();
  }

  fetchUnits = async () => {
    try {
      const unitsData = await apiService.get("/dimensions");
      this.setState({ unitsData });
    } catch (error) {
      console.error("Error fetching units data:", error);
    }
  };

  fetchUserData = async () => {
    try {
      const user = await apiService.get("/users/me");
      // Convert unit IDs in user's data to unit names for display
      const unitNames = user.units.map(
        (unitId) =>
          this.state.unitsData.find((unit) => unit._id === unitId)?.name ||
          unitId
      );
      this.setState({ user: { ...user, units: unitNames.join(", ") } });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  handleEditToggle = () => {
    this.setState((prevState) => ({ editMode: !prevState.editMode }));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      user: { ...prevState.user, [name]: value },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { firstname, lastname } = this.state.user;
    try {
      await apiService.put("/users/" + this.state.user._id, { firstname, lastname });
      this.handleEditToggle(); // Turn off edit mode
      // Optionally, re-fetch user data or show a success message
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  render() {
    const { user, editMode } = this.state;
    const editableFields = ["firstname", "lastname"];

    return (
      <Layout>
        <div className="container">
        <div className="profile-container">
          {" "}
          {/* Add the profile-container class */}
          <h2 className="profile-title">User Profile</h2>{" "}
          {/* Add the profile-title class */}
          {Object.entries(user).map(([key, value]) =>
            editableFields.includes(key) && editMode ? (
              <div key={key} className="profile-field">
                {" "}
                {/* Add the profile-field class */}
                <label>{key}</label>
                <InputText
                  name={key}
                  value={value}
                  onChange={this.handleChange}
                />
              </div>
            ) : !["_id", "isConnectedWithOpenID", "isActive", "__v"].includes(
                key
              ) ? (
              <p key={key} className="profile-info">
                {" "}
                {/* Add the profile-info class */}
                <strong>{key}:</strong> {value}
              </p>
            ) : null
          )}
          <Button
            label={editMode ? "Save" : "Edit"}
            onClick={editMode ? this.handleSubmit : this.handleEditToggle}
            className="profile-button"
          />
        </div></div>
      </Layout>
    );
  }
}

export default UserProfile;
