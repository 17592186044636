import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { AuthProvider } from "./components/auth/AuthProvider";
import reportWebVitals from "./reportWebVitals";
import { msalInstance } from "./components/auth/authConfig";
import { EventType } from "@azure/msal-browser";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

function RootApp() {
  useEffect(() => {
    msalInstance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        msalInstance.setActiveAccount(event.payload.account);
      }
    });
  }, []);

  return (
    <div style={{ background: "#F6F6F6" }}>
      <AuthProvider>
        <App instance={msalInstance} />
      </AuthProvider>
    </div>
  );
}

root.render(<RootApp />);
reportWebVitals(console.log);
