import React, { Component } from "react";
import { ListGroupItem } from "react-bootstrap";
// Assuming you're using PrimeReact for icons, ensure you've installed PrimeReact and imported the necessary CSS
import { Button as PrimeButton } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';

class DimensionItem extends Component {

  handleToggleActiveState = () => {
    // Assuming the existence of a method to toggle the active state in the parent component
    this.props.onToggleActive(this.props.dimension._id);
  };

  handleDelete = () => {
    this.props.onDelete(this.props.dimension._id);
  };

  handleEdit = () => {
    this.props.onEdit(this.props.dimension); // Call the onEdit function passed from the parent component
  };

  render() {
    const { dimension } = this.props;
    return (
      <ListGroupItem
        key={dimension._id}
        style={{ backgroundColor: dimension.isActive ? "" : "darkgray" }}
      >
        <div>
          <div className="row align-items-center">
            <div className="col-10">
              <h3>{dimension.name}</h3>
              <p>{dimension.description}</p>
            </div>
            <div className="col-2">
              <PrimeButton
                icon="pi pi-trash"
                className="p-button-danger m-2"
                onClick={this.handleDelete}
                tooltip="Delete"
              />
              <PrimeButton
                icon="pi pi-pencil"
                className="p-button-primary m-2"
                onClick={this.handleEdit}
                tooltip="Edit"
              />
              <PrimeButton
                icon={dimension.isActive ? "pi pi-times" : "pi pi-check"}
                className={dimension.isActive ? "p-button-warning m-2" : "p-button-success m-2"}
                onClick={this.handleToggleActiveState}
                tooltip={dimension.isActive ? "Deactivate" : "Reactivate"}
              />
            </div>
          </div>
        </div>
      </ListGroupItem>
    );
  }
}

export default DimensionItem;
