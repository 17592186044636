import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import apiService from "../../services/apiServices";
import "../../assets/scss/dashboard.css";

function getStatusColor(status) {
  switch (status) {
    case "Not Started":
      return "#E15759"; // Red color
    case "In Progress":
      return "#ED7D31"; // Amber color
    case "Completed":
      return "#70AD47"; // Green color
    default:
      return "#808080"; // Default color (gray)
  }
}

class LevelOfFunctionalProcesses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      isLoading: true,
      error:null, 
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const data = await apiService.get("/kpis/");
      const chartData = this.processCountryData(data);
      this.setState({
        chartData: chartData,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
      this.setState({ isLoading: false, error: error.toString() });
    }
  }

  processCountryData(rawData) {
    const countryStatusCounts = rawData.reduce((acc, item) => {
      if (!acc[item.country]) {
        acc[item.country] = {
          name: item.country,
          Completed: 0,
          "In Progress": 0,
          "Not Started": 0,
          total: 0,
        };
      }
      if (!acc[item.country][item.status]) {
        acc[item.country][item.status] = 0;
      }
      acc[item.country][item.status] += 1;
      acc[item.country].total += 1;
      return acc;
    }, {});

    const chartData = Object.values(countryStatusCounts);

    // Sort chartData by country name alphabetically
    chartData.sort((a, b) => a.name.localeCompare(b.name));

    // Calculate and add percentage for each status
    chartData.forEach((dataPoint) => {
      const { total } = dataPoint;
      Object.keys(dataPoint).forEach((key) => {
        if (key !== "name" && key !== "total") {
          dataPoint[`${key}Percentage`] =
            ((dataPoint[key] / total) * 100).toFixed(0) + "%";
        }
      });
    });

    return chartData;
  }

  render() {
    const { chartData, isLoading, error } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            layout="vertical"
            width={800}
            height={400}
            data={chartData}
            stackOffset="expand"
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis hide type="number" />
            <YAxis dataKey="name" type="category" />
            <Tooltip />
            <Legend />
            {Object.keys(chartData[0])
              .filter((key) => key.endsWith("Percentage")) // Filter for percentage properties
              .map((percentageKey, index) => {
                const status = percentageKey.replace("Percentage", ""); // Get the original status name
                return (
                  <Bar
                    key={`${status}-${index}`} // Generate a unique key using status and index
                    dataKey={status}
                    stackId="a"
                    fill={getStatusColor(status)}
                  >
                    <LabelList
                      dataKey={percentageKey} // Use the percentage data key
                      position="inside"
                      fill="#FFFFFF" // Set text color to white
                    />
                  </Bar>
                );
              })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default LevelOfFunctionalProcesses;
