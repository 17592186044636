import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import apiService from "../../services/apiServices";
import { ProgressSpinner } from "primereact/progressspinner";


class DimensionComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      globalFilter: null,
      selectedCountry: null,
      selectedDimension: null,
      selectedTopic: null,
      selectedStatus: null,
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const kpis = await apiService.get("/kpis");
      console.log(kpis);
      this.setState({ data: kpis });
    } catch (error) {
      console.error("Error fetching data: ", error);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      data,
      globalFilter,
      selectedCountry,
      selectedDimension,
      selectedTopic,
      selectedStatus,
      isLoading
    } = this.state;

    const statusOptions = [
      { label: "Select Status", value: null },
      { label: "Not Started", value: "Not Started" },
      { label: "In Progress", value: "In Progress" },
      { label: "Completed", value: "Completed" },
    ];

    const countryOptions = [
      { label: "Select Country", value: null },
      ...Array.from(new Set(data.map((item) => item.country))).map(
        (country) => ({
          label: country,
          value: country,
        })
      ),
    ];

    const dimensionOptions = [
      { label: "Select Dimension", value: null },
      ...Array.from(new Set(data.map((item) => item.dimension.name))).map(
        (dimension) => ({
          label: dimension,
          value: dimension,
        })
      ),
    ];

    const topicOptions = [
      { label: "Select Topic", value: null },
      ...Array.from(new Set(data.map((item) => item.topic.name))).map(
        (topic) => ({
          label: topic,
          value: topic,
        })
      ),
    ];

    const filteredData = data.filter(
      (item) =>
        (!selectedCountry || item.country === selectedCountry) &&
        (!selectedDimension || item.dimension.name === selectedDimension) &&
        (!selectedTopic || item.topic.name === selectedTopic) &&
        (!this.state.selectedStatus ||
          item.status === this.state.selectedStatus)
    );

    const statusBodyTemplate = (rowData) => (
      <div
        style={{
          backgroundColor: this.getStatusColor(rowData.status),
          padding: "5px 10px",
          borderRadius: "5px",
          color: "#fff",
          textAlign: "center",
          textWeight: "bold",
        }}
      >
        {rowData.status}
      </div>
    );

    return (
      <>
      {!isLoading ? (
          <div
            className="p-d-flex p-jc-center p-ai-center"
            style={{ height: "100px" }}
          >
            <ProgressSpinner />
          </div>
        ) : (
          <>
        <div className="row m-3">
          <div className="p-fluid">
            <div className="p-field p-grid">
              <div className="row">
                <div className="col-3">
                  <label htmlFor="country" className="p-col-4 p-md-1">
                    Country
                  </label>
                  <div className="p-col-12 p-md-2">
                    <Dropdown
                      id="country"
                      value={selectedCountry}
                      options={countryOptions}
                      onChange={(e) =>
                        this.setState({ selectedCountry: e.value })
                      }
                      placeholder="Filter by Country"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <label htmlFor="dimension" className="p-col-4 p-md-1">
                    Functional Area
                  </label>
                  <div className="p-col-4 p-md-2">
                    <Dropdown
                      id="dimension"
                      value={selectedDimension}
                      options={dimensionOptions}
                      onChange={(e) =>
                        this.setState({ selectedDimension: e.value })
                      }
                      placeholder="Filter by Dimension"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <label htmlFor="topic" className="p-col-12 p-md-1">
                    Topic
                  </label>
                  <div className="p-col-12 p-md-2">
                    <Dropdown
                      id="topic"
                      value={selectedTopic}
                      options={topicOptions}
                      onChange={(e) =>
                        this.setState({ selectedTopic: e.value })
                      }
                      placeholder="Filter by Topic"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <label htmlFor="status" className="p-col-12 p-md-1">
                    Status
                  </label>
                  <div className="p-col-12 p-md-2">
                    <Dropdown
                      id="status"
                      value={selectedStatus}
                      options={statusOptions}
                      onChange={(e) =>
                        this.setState({ selectedStatus: e.value })
                      }
                      placeholder="Filter by Status"
                    />
                  </div>
                </div>
              </div>
              <label htmlFor="search" className="p-col-12 p-md-1">
                Search
              </label>
              <div className="p-col-12 p-md-2">
                <InputText
                  id="search"
                  type="text"
                  value={globalFilter}
                  onChange={(e) =>
                    this.setState({ globalFilter: e.target.value })
                  }
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        <DataTable
          value={filteredData}
          globalFilter={globalFilter}
          paginator stripedRows={true} stripArrows={true}
          rows={5}
          rowsPerPageOptions={[5, 10, 15]}
          emptyMessage="No records found"
        >
          <Column field="country" header="Country" sortable />
          <Column field="dimension.name" header="Functional Area" sortable />
          <Column field="topic.name" header="Topic" sortable />
          <Column field="comment" header="Comment" />
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable
          />
        </DataTable>
        </>
        )}
      </>
    );
  }

  getStatusColor(status) {
    switch (status) {
      case "Not Started":
        return "#E15759"; // Red color
      case "In Progress":
        return "#ED7D31"; // Amber color
      case "Completed":
        return "#70AD47"; // Green color
      default:
        return "#808080"; // Default color (gray)
    }
  }
}

export default DimensionComments;
