import axios from "axios";

const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
    "Access-Control-Allow-Credentials": "true",
  },
};

class ApiService {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL: baseURL,
      withCredentials: true,
      ...config,
    });
    axios.defaults.withCredentials = true;
  }

  // Method to set up any headers you need to send with every request
  setHeader(header, value) {
    this.api.defaults.headers.common[header] = value;
  }

  // Handling GET request
  async get(endpoint) {
    try {
      const response = await this.api.get(endpoint);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // Async/Await method for POST request
  async post(endpoint, data) {
    try {
      const response = await this.api.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // Async/Await method for PUT request
  async put(endpoint, data) {
    try {
      const response = await this.api.put(endpoint, data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // Async/Await method for DELETE request
  async delete(endpoint) {
    try {
      const response = await this.api.delete(endpoint);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // Async/Await method for PATCH request
  async patch(endpoint, data) {
    try {
      const response = await this.api.patch(endpoint, data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

// You can create an instance of the ApiService with your base API URL like this:
const apiService = new ApiService(  process.env.REACT_APP_API_URL);

export default apiService;
