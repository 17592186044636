import React, { Component } from "react";
import apiService from "../../services/apiServices";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import '../../assets/scss/logs.css'

class LogsComponent extends Component {
  state = {
    logs: [],
    isLoading: true,
  };

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const logs = await apiService.get("/logs");
      this.setState({ logs });
    } catch (error) {
      console.error("Error fetching logs:", error);
      this.setState({ isLoading: false });
      // Handle errors, like showing a message to the user
    }
  }
  formatLogsForDisplay(logs) {
    return logs
      .map((log) => {
        const timestamp = moment(log.timestamp).format("YYYY-MM-DD HH:mm:ss");
        return `[${timestamp}] ${log.method} ${log.path} - User: ${
          log.userEmail
        } - Data: ${JSON.stringify(log.body)}`;
      })
      .join("\n");
  }

  render() {
    const { logs, isLoading } = this.state;

    if (!isLoading) {
      return (
        <div className="logs-container">
          <h2>Logs</h2>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    }

    if (!logs.length) {
      return (
        <div className="logs-container">
          <h2>Logs</h2>
          <p>No logs available.</p>
        </div>
      );
    }

    const logsText = this.formatLogsForDisplay(logs);

    return (
      <div className="logs-container">
        <h2>Logs</h2>
        <textarea value={logsText} readOnly className="log-text-area" />
      </div>
    );
  }
}

export default LogsComponent;
