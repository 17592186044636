import React from 'react';
import './../../assets/scss/Footer.css'; // Ensure this file is updated based on the CSS below

const FooterUI = () => {
  const focalPoints = [
    { name: 'Maite Santos', email: 'maite.santos@wfp.org', area: 'CP Management' },
    { name: 'Ariuntuya Tsendayush', email: 'ariuntuya.tsendayush@wfp.org', area: 'Monitoring' },
    { name: 'Rachida Aouameur', email: 'rachida.aouameur@wfp.org', area: 'CFM' },
    { name: 'Natasha Frosina', email: 'natasha.frosina@wfp.org', area: 'Identity Management' },
    { name: 'Nuru Jumaine', email: 'nuru.jumaine@wfp.org', area: 'Commodity Management' },
    { name: 'Stephen Omuga', email: 'stephen.omuga@wfp.org', area: 'AFAC' },
  ];
  

  return (
    <footer className="footer">
      <div className="container">
        <h4>Focal Points</h4>
        <div className="focal-points">
          {focalPoints.map((point, index) => (
            <div key={index} className="focal-point">
              <p className="area">{point.area}</p>
              <p className="name"><strong>{point.name}</strong></p>
              <p className="email">{point.email}</p>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default FooterUI;
