import React, { Component } from "react";
import Layout from "../components/layouts/layout";
import DimensionTabsComponent from "../components/data/datatable";

class Data extends Component {
  render() {
    return (
      <Layout>
        <div className="container">
          <DimensionTabsComponent />
        </div>
      </Layout>
    );
  }
}

export default Data;
