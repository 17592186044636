import React, { Component } from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Label,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
} from "recharts";
import apiService from "../../services/apiServices";
import { bold } from "fontawesome";
import "../../assets/scss/dashboard.css";
import { ProgressSpinner } from "primereact/progressspinner";

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = outerRadius + 10; // Position the label slightly outside of the outer radius
  const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
  const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class DimensionPieCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: [],
      kpis: [],
      selectedCountry: "",
      countries: [],
    };
  }

  async componentDidMount() {
    try {
      const kpis = await apiService.get("/kpis");
      const dimensionSet = new Set(kpis.map((kpi) => kpi.dimension.name));
      // Extract countries directly from the KPIs data
      const countrySet = new Set(
        kpis
          .map(
            (kpi) =>
               kpi.country //&& { id: kpi.country.id, name: kpi.country.name }
          )
          .filter((country) => country)
      );
      this.setState({
        dimensions: Array.from(dimensionSet),
        kpis,
        countries: Array.from(countrySet),
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  handleCountryChange = (event) => {
    this.setState({ selectedCountry: event.target.value });
  }

  renderCountrySelector() {
    const { countries, selectedCountry } = this.state;
    return (
      <div className="country-selector-container">
        <label htmlFor="countrySelector" className="country-selector-label">Choose a Country:</label>
        <select
          id="countrySelector"
          value={selectedCountry}
          onChange={this.handleCountryChange}
          className="country-selector"
        >
          <option value="">Select a Country</option>
          {countries.map((country) => (
            <option key={country} value={country}>{country}</option>
          ))}
        </select>
      </div>
    );
  }
  

  render() {
    const { dimensions, kpis, selectedCountry } = this.state;

    const filteredKpis = selectedCountry ? kpis.filter(kpi => kpi.country && kpi.country === selectedCountry) : kpis;

    return (
      
      <div className="row justify-content-center align-items-center">
        {this.renderCountrySelector()}  
        {dimensions.map((dimension) => (
          <div
            key={dimension}
            className=""
            style={{
              height: "auto",
              margin: "0px",
              width: "auto"
            }}
          >
            <DimensionPieChart dimension={dimension} kpis={filteredKpis} />
            <hr />
          </div>
        ))}
      </div>
    );
  }
}

class DimensionPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeIndex: null,
      topicData: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dimension !== this.props.dimension ||
      prevProps.kpis !== this.props.kpis
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    this.setState({ isLoading: true });
    const { dimension, kpis } = this.props;
    const filteredKpis = kpis.filter((kpi) => kpi.dimension.name === dimension);
    const countsByStatus = filteredKpis.reduce((acc, kpi) => {
      acc[kpi.status] = (acc[kpi.status] || 0) + 1;
      return acc;
    }, {});
    const totalCount = Object.values(countsByStatus).reduce(
      (acc, count) => acc + count,
      0
    );
    const chartData = Object.keys(countsByStatus).map((status) => ({
      name: status,
      value: (countsByStatus[status] / totalCount) * 100, // Calculate percentage
      fill: this.getStatusColor(status),
    }));
    this.setState({ data: chartData, isLoading: false });
  }

  getStatusColor(status) {
    switch (status) {
      case "Not Started":
        return "#E15759"; // Red color
      case "In Progress":
        return "#ED7D31"; // Amber color
      case "Completed":
        return "#70AD47"; // Green color
      default:
        return "#808080"; // Default color (gray)
    }
  }

  handlePieClick = (data, index) => {
    if (!this.state.activeIndex) {
      this.setState({ activeIndex: index });
      console.log(data);
      this.fetchTopicData(data.name);
    } else {
      this.setState({ activeIndex: null });
    }
  };

  fetchTopicData(status) {
    this.setState({ isLoading: true });
    const { dimension, kpis } = this.props;
    const filteredKpis = kpis.filter(
      (kpi) => kpi.dimension.name === dimension && kpi.status === status
    );
    const countsByTopic = filteredKpis.reduce((acc, kpi) => {
      acc[kpi.topic.name] = (acc[kpi.topic.name] || 0) + 1;
      return acc;
    }, {});
    const topicData = Object.keys(countsByTopic).map((topic) => ({
      name: topic,
      count: countsByTopic[topic],
    }));
    this.setState({ topicData });
    this.setState({ isLoading: false });
  }

  render() {
    const { dimension } = this.props;
    const { data, activeIndex, topicData, isLoading } = this.state;

    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <ProgressSpinner />
        </div>
      );
    }
    return (
      <div className="pie-chart-container">
        <PieChart width={300} height={200}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            innerRadius={40}
            fill="#8884d8"
            onClick={this.handlePieClick}
            label={renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
            <Label
              value={`${dimension}`}
              position="center"
              fontSize={13}
              fontWeight={bold}
              fill="#000"
              style={{ textWrap: "wrap" }}
            />
          </Pie>

          <Tooltip formatter={(value) => `${Math.round(value)}%`} />
        </PieChart>
        {activeIndex !== null && (
          <div className="status-bar-chart">
            <h3>Status: {data[activeIndex].name}</h3>
            <BarChart width={400} height={200} data={topicData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis type="number" hide />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="count"
                label
                fill={this.getStatusColor(data[activeIndex].name)}
              />
            </BarChart>
          </div>
        )}
      </div>
    );
  }
}

export default DimensionPieCharts;
