import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [params] = useSearchParams();
    
    return (
      <Component
        navigate={navigate}
        params = {params}
        location={location}
        {...props}
        />
    );
  };
  
  return Wrapper;
};