import React from "react";
import "../assets/scss/login.css";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "../hooks/withRouter";
import apiService from "../services/apiServices";
import logoBlue from "../assets/images/logo-blue.png";
import { AuthContext } from "../components/auth/AuthProvider";
import { withCookies } from "react-cookie";
import { msalInstance } from "../components/auth/authConfig";

class LoginPage extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isSubmitting: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSSO = this.handleSSO.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleOnChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleError(err) {
    toast.error(err, {
      position: "bottom-left",
    });
  }

  handleSuccess(msg) {
    toast.success(msg, {
      position: "bottom-left",
    });
  }

  async handleSSO(e) {
    e.preventDefault();
    const { email } = this.state;
    const emailDomain = email.split("@")[1];
    if (emailDomain === process.env.REACT_APP_DOMAIN_NAME) {
      try {
        await msalInstance.loginRedirect({
          scopes: ["user.read"],
        });
      } catch (error) {
        console.error("SSO Login Error:", error);
        this.handleError("SSO login failed. Please try again.");
      }
    } else {
      this.handleError("Please use a WFP email to use SSO.");
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    const emailDomain = email.split("@")[1];

    if (emailDomain === process.env.REACT_APP_DOMAIN_NAME) {
      // If user tries to login with WFP domain via normal login, redirect to SSO
      this.handleSSO(e);
    } else {
      // Proceed with regular login
      this.loginWithPassword(email, password);
    }
  }

  loginWithPassword(email, password) {
    this.setState({ isSubmitting: true });
    apiService
      .post("/users/login", {
        email,
        password,
      })
      .then((data) => {
        const { success, message, token } = data;
        if (success) {
          this.handleSuccess(message);
          // console.log(message)
          this.props.cookies.set("authToken", token, {
            path: "/",
            httpOnly: false, // Ensure it's accessible by JavaScript
            secure: process.env.NODE_ENV === "production", // Only use secure in production
            SameSite: "Lax", // Default setting, adjust as necessary
          });
          this.context.checkAuthentication();
          setTimeout(() => {
            this.props.navigate("/");
          }, 500);
          this.setState(
            {
              email: "",
              password: "",
              isSubmitting: false,
            },
            () => {}
          );
        } else {
          this.handleError(message);
          this.setState({
            email: "",
            password: "",
            isSubmitting: false,
          });
        }
      })
      .catch((error) => {
        console.error("Login error:", error);
        this.handleError(error.toString());
        this.setState({
          email: "",
          password: "",
          isSubmitting: false,
        });
      });
  }

  render() {
    const { email, password, isSubmitting } = this.state;
    const emailDomain = email.split("@")[1];
    const showPasswordInput = emailDomain !== process.env.REACT_APP_DOMAIN_NAME;

    return (
      <div className="login-container">
        <div className="login-form">
          <div className="logo align-items-center d-flex justify-content-center">
            <img
              src={logoBlue}
              alt="Logo"
              width="50"
              height="50"
              className="d-inline-block align-top"
            />
            <h1>Login</h1>
          </div>
          <p className="subtext">
            If you have a WFP account, please enter your email to connect via Single Sign-On.
          </p>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleOnChange}
                required
              />
            </div>
            {showPasswordInput && (
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={this.handleOnChange}
                  required
                />
              </div>
            )}
            <div className="form-group">
              <button
                type="submit"
                className="login-button mb-1"
                disabled={isSubmitting}
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(withCookies(LoginPage));
