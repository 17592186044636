import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // This hook provides user and authentication status

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();
  console.log(user, isAuthenticated, roles, location);
  // Check if the user is authenticated and if they have one of the required roles
  if (!isAuthenticated) {
    // User is not authenticated, redirect to login page
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (!roles.includes(user.role)) {
    // User is authenticated but doesn't have the required role, redirect to unauthorized page or home
    return <Navigate to="/unauthorized" state={{ from: location }} />;
  } else {
    // User is authenticated and has the required role, render the requested component
    return <Component {...rest} />;
  }
};

export default ProtectedRoute;
