import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import apiService from "../../../services/apiServices";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Spinner } from "react-bootstrap";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";

class UserList extends Component {
  state = {
    users: [],
    dimensions: [],
    filteredUsers: [],
    roles: [
      { label: "Member", value: "member" },
      { label: "Admin", value: "admin" },
      { label: "Focal Point", value: "focalPoint" },
    ],
    isloading: true,
    showAddUserModal: false,
    newUser: {
      firstname: "",
      lastname: "",
      email: "",
      role: "",
      units: [],
    },
    globalFilter: null,
  };

  async componentDidMount() {
    await this.fetchDimensions();
    await this.fetchUsers();
  }
  addNewUser = async () => {
    const { newUser } = this.state;
    newUser.password = "P@ssword";
    console.log("Adding a new user", newUser);
    try {
      await apiService.post("/users/register", newUser);
      this.fetchUsers(); // Refresh the user list after adding a new user
      this.closeNewUserModal(); // Close the modal after the operation
    } catch (error) {
      console.error("Error adding new user:", error);
      // Optionally, handle the error in the UI, such as displaying a notification
    }
  };

  openNewUserModal = () => {
    this.setState({ showAddUserModal: true });
  };

  closeNewUserModal = () => {
    this.setState({ showAddUserModal: false });
  };

  fetchUsers = async () => {
    try {
      this.setState({ isLoading: true });
      let users = await apiService.get("/users");
      users = users.map((user) => ({
        ...user,
        units: user.units
          .map(
            (unitId) =>
              this.state.dimensions.find((d) => d.value === unitId)?.label ||
              unitId
          )
          .join(", "),
      }));
      this.setState({ users, filteredUsers: users });
    } catch (error) {
      console.error("Error fetching users:", error);
      this.setState({ isLoading: false });
    }
  };

  updateFilteredUsers = () => {
    const { users, globalFilter } = this.state;
    let filteredUsers = users;

    if (globalFilter) {
      filteredUsers = users.filter((user) => {
        return (
          user.firstname.toLowerCase().includes(globalFilter.toLowerCase()) ||
          user.lastname.toLowerCase().includes(globalFilter.toLowerCase()) ||
          user.email.toLowerCase().includes(globalFilter.toLowerCase())
        );
      });
    }

    this.setState({ filteredUsers });
  };

  handleGlobalFilterChange = (e) => {
    this.setState({ globalFilter: e.target.value }, () =>
      this.updateFilteredUsers()
    );
  };

  fetchDimensions = async () => {
    this.setState({ isLoading: true });
    try {
      const dimensions = await apiService.get("/dimensions");
      const formattedDimensions = dimensions.map((dimension) => ({
        label: dimension.name,
        value: dimension._id,
      }));
      this.setState({ dimensions: formattedDimensions });
    } catch (error) {
      console.error("Error fetching dimensions:", error);
      this.setState({ isLoading: false });
    }
  };

  lastLoginTemplate = (rowData) => {
    return (
      <span>
        {rowData.lastLogin ? moment(rowData.lastLogin).format("LLL") : "Never"}
      </span>
    );
  };

  onRowEditComplete = async (e) => {
    console.log(e);
    const newData = e.newData; // Correctly structured for clarity
    console.log(newData);
    const unitIds = newData.units
      .split(", ")
      .map(
        (name) => this.state.dimensions.find((d) => d.label === name)?.value
      );

    const updatedUser = {
      ...newData,
      units: unitIds,
    };

    try {
      await apiService.put(`/users/${newData._id}`, updatedUser);
      this.fetchUsers(); // Refresh the list of users to show updated data
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  unitEditor = (options) => {
    // Transform names back to IDs for editing
    const dimensionIds = options.value
      .split(", ")
      .map((name) => this.state.dimensions.find((d) => d.label === name)?.value)
      .filter(Boolean);
    return (
      <MultiSelect
        value={dimensionIds}
        options={this.state.dimensions}
        onChange={(e) => {
          // Transform IDs back to names for display
          const unitNames = e.value.map(
            (id) =>
              this.state.dimensions.find((d) => d.value === id)?.label || id
          );
          options.editorCallback(unitNames.join(", "));
        }}
      />
    );
  };

  roleEditor = (options) => {
    return (
      <Dropdown
        value={options.rowData["role"]}
        options={this.state.roles}
        onChange={(e) => {
          // Directly update the role field in the rowData
          // const updatedRow = { ...options.rowData, role: e.value };
          // Pass the updated row data back to the DataTable
          options.editorCallback(e.value);
          console.log(options.rowData, e.value);
        }}
        placeholder="Select a role"
        optionLabel="label"
      />
    );
  };

  toggleOpenIDConnection = async (user, isChecked) => {
    // Construct the new user data
    const updatedUserData = { ...user, isConnectedWithOpenID: isChecked };
    try {
      await apiService.put(`/users/${user._id}`, updatedUserData);
      this.fetchUsers(); // Refresh the list of users
    } catch (error) {
      console.error("Error updating user OpenID connection:", error);
    }
  };

  render() {
    const { isLoading, showAddUserModal, newUser, dimensions, filteredUsers } =
      this.state;
    const addUserDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={this.closeNewUserModal}
          className="p-button-text"
        />
        <Button
          label="Add"
          icon="pi pi-check"
          onClick={this.addNewUser}
          autoFocus
        />
      </React.Fragment>
    );
    return (
      <div>
        <h2>Users</h2>
        <Button
          label="Add New User"
          icon="pi pi-plus"
          onClick={this.openNewUserModal}
          className="p-button-raised p-button-success"
          style={{ marginBottom: "10px" }}
        />
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon">
            <i className="pi pi-search"></i>
          </span>
          <InputText
            value={this.state.globalFilter}
            onChange={this.handleGlobalFilterChange}
            placeholder="Search by name or email"
          />
        </div>
        {!isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <DataTable
            value={filteredUsers}
            editMode="row"
            dataKey="_id"
            onRowEditComplete={this.onRowEditComplete}
          >
            <Column
              field="firstname"
              header="First Name"
              editor={(props) => (
                <InputText
                  value={props.rowData.firstname}
                  onChange={(e) => props.editorCallback(e.target.value)}
                />
              )}
            />
            <Column
              field="lastname"
              header="Last Name"
              editor={(props) => (
                <InputText
                  value={props.rowData.lastname}
                  onChange={(e) => {
                    console.log(props.rowData, e.target.value);
                    props.editorCallback(e.target.value);
                  }}
                />
              )}
            />
            <Column field="email" header="Email" />
            <Column
              field="role"
              header="Role"
              editor={this.roleEditor}
              body={(rowData) => rowData.role}
            />
            <Column
              field="units"
              header="Units"
              body={(rowData) => rowData.units}
              editor={this.unitEditor}
            />
            <Column
              field="isActive"
              header="Active"
              body={(rowData) => (
                <i
                  className={rowData.isActive ? "pi pi-unlock" : "pi pi-lock"}
                  style={{
                    color: rowData.isActive ? "green" : "red",
                    fontSize: "1.5rem",
                  }}
                ></i>
              )}
              editor={(props) => (
                <InputSwitch
                  checked={props.rowData.isActive}
                  onChange={(e) => props.editorCallback(e.value)}
                />
              )}
            />
            <Column
              field="isConnectedWithOpenID"
              header="Connected via SSO"
              body={(rowData) => (
                <InputSwitch
                  checked={rowData.isConnectedWithOpenID}
                  onChange={(e) =>
                    this.toggleOpenIDConnection(rowData, e.value)
                  }
                />
              )}
              editor={(props) => (
                <InputSwitch
                  checked={props.rowData.isConnectedWithOpenID}
                  onChange={(e) => {
                    console.log(props.rowData, e.value);
                    props.editorCallback(e.value);
                  }}
                />
              )}
            />
            <Column
              rowEditor
              headerStyle={{ width: "7rem", textAlign: "center" }}
              bodyStyle={{ textAlign: "center" }}
            />
          </DataTable>
        )}
        <Dialog
          visible={showAddUserModal}
          style={{ width: "450px" }}
          header="Add New User"
          modal
          className="p-fluid"
          footer={addUserDialogFooter}
          onHide={this.closeNewUserModal}
        >
          <div className="p-field">
            <label htmlFor="firstname">First Name</label>
            <InputText
              id="firstname"
              value={newUser.firstname}
              onChange={(e) =>
                this.setState({
                  newUser: { ...newUser, firstname: e.target.value },
                })
              }
            />
          </div>
          <div className="p-field">
            <label htmlFor="lastname">Last Name</label>
            <InputText
              id="lastname"
              value={newUser.lastname}
              onChange={(e) =>
                this.setState({
                  newUser: { ...newUser, lastname: e.target.value },
                })
              }
            />
          </div>
          <div className="p-field">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={newUser.email}
              onChange={(e) =>
                this.setState({
                  newUser: { ...newUser, email: e.target.value },
                })
              }
            />
          </div>
          <div className="p-field">
            <label htmlFor="role">Role</label>
            <Dropdown
              value={newUser.role}
              options={this.state.roles}
              onChange={(e) =>
                this.setState({
                  newUser: {
                    ...newUser,
                    role: e.value,
                    units: e.value === "focalPoint" ? newUser.units : [],
                  },
                })
              }
              placeholder="Select a role"
              optionLabel="label"
            />
          </div>
          {newUser.role === "focalPoint" && (
            <div className="p-field">
              <label htmlFor="units">Units</label>
              <MultiSelect
                value={newUser.units}
                options={dimensions}
                onChange={(e) =>
                  this.setState({ newUser: { ...newUser, units: e.value } })
                }
                optionLabel="label"
                placeholder="Select units"
                display="chip"
                showSelectAll={false}
              />
            </div>
          )}
          <div className="p-field">
            <label htmlFor="openIdConnect">Must Connect via OpenID</label>
            <InputSwitch
              checked={newUser.isConnectedWithOpenID || false}
              onChange={(e) =>
                this.setState({
                  newUser: { ...newUser, isConnectedWithOpenID: e.value },
                })
              }
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default UserList;
