import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
// import { InputText } from 'primereact/inputtext';
import { Fieldset } from "primereact/fieldset";
import apiService from "../../services/apiServices";
import { Toast } from "primereact/toast";

class AddKPIComponent extends Component {
  constructor(props) {
    super(props);
    this.toast = React.createRef();
    this.state = {
      dimensions: [],
      topics: [],
      countries: [
        { label: "Burkina Faso" },
        { label: "Cameroon" },
        { label: "CAR" },
        { label: "Chad" },
        { label: "Mali" },
        { label: "Niger" },
        { label: "Nigeria" },
      ],
      selectedDimension: null,
      selectedTopic: null,
      selectedCountry: null,
      status: "",
      comment: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      // Use Promise.all to wait for both fetch operations to complete
      const [dimensionsResponse, topicsResponse] = await Promise.all([
        apiService.get("/dimensions"),
        apiService.get("/topics"),
      ]);
      console.log(dimensionsResponse);
      this.setState({
        dimensions: dimensionsResponse,
        topics: topicsResponse,
        isLoading: false, // Set loading to false after fetch operations complete
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ isLoading: false });
    }
  };

  onDimensionChange = (e) => {
    this.setState({
      selectedDimension: e.value,
      selectedTopic: null, // Reset topic selection on dimension change
    });
  };

  onTopicChange = (e) => {
    this.setState({ selectedTopic: e.value });
  };

  onCountryChange = (e) => {
    this.setState({ selectedCountry: e.value });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  saveKPI = async () => {
    const { selectedTopic, selectedCountry, comment, status } = this.state;

    const kpiData = {
      topic: selectedTopic,
      country: selectedCountry,
      comment: comment.trim(),
      status,
    };

    try {
      const response = await apiService.post("/kpis", kpiData);
      console.log("KPI saved successfully:", response);

      // Show success toast
      this.toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "KPI saved successfully",
        life: 3000, // Duration in milliseconds for which the toast is visible
      });

      // Optionally, reset form fields here
    } catch (error) {
      console.error("Error saving KPI:", error);
      // Optionally, show an error toast here
    }
  };

  render() {
    const {
      isLoading,
      dimensions,
      topics,
      selectedDimension,
      selectedTopic,
      countries,
      selectedCountry,
      status,
      comment,
    } = this.state;
    const filteredTopics = selectedDimension
      ? topics.filter((topic) => topic.dimension._id === selectedDimension)
      : [];
    if (isLoading) {
      return <div>Loading...</div>; // Render loading state or spinner
    }

    console.log(dimensions);
    return (
      <div className="p-fluid p-formgrid p-grid">
        <h3>Add New KPI</h3>
        <Fieldset>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="dimension">Dimension</label>
            <Dropdown
              id="dimension"
              value={selectedDimension}
              options={dimensions.map((dim) => ({
                label: dim.name,
                value: dim._id,
              }))}
              onChange={this.onDimensionChange}
              placeholder="Select Dimension"
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="topic">Topic</label>
            <Dropdown
              id="topic"
              value={selectedTopic}
              options={filteredTopics.map((topic) => ({
                label: topic.name,
                value: topic._id,
              }))}
              onChange={this.onTopicChange}
              placeholder="Select Topic"
              disabled={!selectedDimension}
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="country">Country</label>
            <Dropdown
              id="country"
              value={selectedCountry}
              options={countries.map((country) => ({
                label: country.label,
                value: country.label,
              }))}
              onChange={this.onCountryChange}
              placeholder="Select Country"
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="status">Status</label>
            <Dropdown
              id="status"
              value={status}
              options={[
                { label: "Not Started", value: "Not Started" },
                { label: "In Progress", value: "In Progress" },
                { label: "Completed", value: "Completed" },
              ]}
              onChange={this.handleInputChange}
              placeholder="Select Status"
              name="status"
            />
          </div>
          <div className="p-field p-col-12">
            <label htmlFor="comment">Comment</label>
            <InputTextarea
              id="comment"
              value={comment}
              onChange={this.handleInputChange}
              rows={4}
              placeholder="Comment"
              name="comment"
            />
          </div>
        </Fieldset>
        <Button label="Save KPI" onClick={this.saveKPI} />
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default AddKPIComponent;
