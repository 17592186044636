import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { withCookies } from "react-cookie";
import { withRouter } from "../../hooks/withRouter";
import "../../assets/scss/UserPanel.css";

class UserPanel extends Component {
  navigateToProfile = () => {
    this.props.navigate("/profile");
  };

  handleLogout = () => {
    // Remove the token from the cookies
    this.props.cookies.remove("authToken", { path: "/" });

    // Call onHide to close the panel (assuming this also updates the parent state to reflect logout)
    this.props.onHide();

    // Redirect to the login page
    this.props.navigate("/login");
  };
  render() {
    // Destructure props for easier access
    const { visible, onHide, user } = this.props;

    return (
      <Dialog
        header={`Welcome ${user.firstname} ${user.lastname}`}
        visible={visible}
        style={{
          width: "30vw", // Adjust the width as needed
          height: "100vh", // Full height
          position: "fixed", // Fixed position
          right: 0, // Position on the right
          top: 0,
          maxHeight: "100%", // Full height;
          // Position at the top
        }}
        modal
        onHide={onHide}
      >
        {/* Content of the panel */}
        <div className="user-info">
          <div style={{ marginBottom: "10px" }}>
            <Button
              icon="pi pi-user"
              href="/profile"
              label="Profile"
              onClick={this.navigateToProfile}
              className="p-button-lg"
            />{" "}
            {/* Add the p-button-lg class */}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Button
              icon="pi pi-sign-out"
              label="Sign Out"
              onClick={this.handleLogout}
              className="p-button-lg"
            />{" "}
            {/* Add the p-button-lg class */}
          </div>
        </div>
        
      </Dialog>
    );
  }
}

export default withRouter(withCookies(UserPanel));
