import React, { Component } from "react";
import { withRouter } from "../hooks/withRouter";
import apiService from "../services/apiServices";
import { withCookies } from "react-cookie";
import { AuthContext } from "../components/auth/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { msalInstance } from "../components/auth/authConfig";
import "../assets/scss/redirect.css";

function splitName(fullName) {
  const parts = fullName.trim().split(" ");
  if (parts.length > 1) {
    const lastName = parts.pop(); // Remove and get the last element as the last name
    const firstName = parts.join(" "); // The rest are the first name
    return { firstName, lastName };
  } else {
    return { firstName: fullName, lastName: "" }; // Handle the case with only one part
  }
}

class AuthRedirectHandler extends Component {
  static contextType = AuthContext;

  componentDidMount() {

    
    if (!msalInstance) {
      console.error("MSAL Instance is not initialized.");
      this.props.navigate("/login");
      return;
    }

    msalInstance.handleRedirectPromise()
      .then((tokenResponse) => {
        if (tokenResponse && tokenResponse.account) {
          msalInstance.setActiveAccount(tokenResponse.account);
          this.authenticateWithBackend(tokenResponse.account);
        } else {
          console.log("Redirect completed, but no account found.");
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
            this.authenticateWithBackend(accounts[0]);
          } else {
            this.props.navigate("/login");
          }
        }
      })
      .catch((error) => {
        console.error("Failed to handle redirect:", error);
        this.props.navigate("/login");
      });
  }

  handleSuccess(msg) {
    toast.success(msg, {
      position: "bottom-left",
    });
  }

  authenticateWithBackend = (userClaims) => {
    const { firstName, lastName } = splitName(userClaims.name);
    const user = {
      email: userClaims.username,
      firstname: firstName,
      lastname: lastName,
      openid: true,
    };

    apiService.post("/users/login", user)
      .then((data) => {
        if (data.success) {
          this.props.cookies.set("authToken", data.token, {
            path: "/",
            httpOnly: false, // Ensure it's accessible by JavaScript
            secure: process.env.NODE_ENV === "production", // Only use secure in production
            SameSite: "Lax", // Default setting, adjust as necessary
          });
          this.handleSuccess(data.message);
          setTimeout(() => {
            this.props.navigate("/");
          }, 500);
        } else if (data.requestAccess) {
          this.props.navigate("/request-access", {
            state: { email: data.email },
          });
        } else {
          this.props.navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error authenticating with backend:", error);
        this.props.navigate("/login");
      });
  }

  render() {
    return (
      <>
        <div className="loading-container">
          <ProgressSpinner />
          <div className="loading-text">Loading authentication result...</div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default withRouter(withCookies(AuthRedirectHandler));
