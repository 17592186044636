import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/scss/Unauthorized.css'; // Assuming you will create this CSS file

const Unauthorized = () => {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1>403</h1>
        <h2>Access Denied</h2>
        <p>You do not have permission to view this page.</p>
        <Link to="/" className="home-link">Go Back Home</Link>
      </div>
    </div>
  );
};

export default Unauthorized;
