// DimensionList.js
import React, { Component } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { Button as PrimeButton } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

import apiService from "../../../services/apiServices.js";
import DimensionItem from "./DimensionItem";
import AddEditDimensionForm from "./AddEditDimensionForm.jsx"; // Create a form for adding/editing dimensions

class DimensionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: [],
      showModal: false,
      editDimension: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchDimensions();
  }

  fetchDimensions = async () => {
    try {
      this.setState({ isLoading: true });
      const data = await apiService.get("/dimensions");
      this.setState({ dimensions: data });
    } catch (error) {
      console.error("Error fetching dimensions:", error);
      this.setState({ isLoading: false });
    }
  };

  onToggleActive = async (dimensionId) => {
    try {
      const { dimensions } = this.state;
      const dimension = dimensions.find((d) => d._id === dimensionId);
      if (!dimension) {
        throw new Error("Dimension not found");
      }

      // Toggle the active state
      const newState = !dimension.isActive;
      await apiService.put(
        `/dimensions/${dimensionId}/${newState ? "activate" : "deactivate"}`
      );

      // Update the dimension in the local state
      const updatedDimensions = dimensions.map((d) =>
        d._id === dimensionId ? { ...d, isActive: newState } : d
      );
      this.setState({ dimensions: updatedDimensions });
    } catch (error) {
      console.error("Error toggling dimension active state:", error);
    }
  };

  handleShowModal = (editDimension) => {
    this.setState({ showModal: true, editDimension });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, editDimension: null });
  };

  handleAddDimension = async (newDimension) => {
    this.setState((prevState) => ({
      dimensions: [...prevState.dimensions, newDimension],
      showModal: false,
    }));
  };

  handleEditDimension = async (updatedDimension) => {
    const { dimensions } = this.state;
    console.log(dimensions);
    const updatedDimensions = dimensions.map((dim) => {
      if (dim._id === updatedDimension.id) {
        return updatedDimension;
      }
      return dim;
    });
    this.setState({
      dimensions: updatedDimensions,
      showModal: false,
      editDimension: null,
    });
  };

  handleDelete = async (id) => {
    try {
      await apiService.delete(`/dimensions/${id}`);
      this.setState({
        dimensions: this.state.dimensions.filter((dim) => dim.id !== id),
      });
    } catch (error) {
      console.error("Error deleting dimension:", error);
    }
  };

  render() {
    const { dimensions, showModal, editDimension, isLoading } = this.state;
    // console.log(dimensions);
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h2>Dimensions</h2>
          <PrimeButton
            variant="primary"
            // style={{ height: "50px", width: "250px", fontSize: "20px" }}
            className="font-weight-bold m-3 text-center"
            onClick={() => this.handleShowModal(null)}
          >
            Add a new dimension
          </PrimeButton>
        </div>
        {!isLoading ? (
          <div
            className="p-d-flex p-jc-center p-ai-center"
            style={{ height: "100px" }}
          >
            <ProgressSpinner />
          </div>
        ) : (
          <ListGroup>
            {dimensions.map((dim) => (
              <div className="card m-3">
                <DimensionItem
                  key={dim._id}
                  dimension={dim}
                  onToggleActive={this.onToggleActive}
                  onDelete={this.handleDelete}
                  onEdit={this.handleShowModal}
                />
              </div>
            ))}
          </ListGroup>
        )}
        {/* Modal for adding/editing dimension */}
        <Modal show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {editDimension ? "Edit Dimension" : "Add Dimension"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditDimensionForm
              dimension={editDimension}
              onAdd={this.handleAddDimension}
              onEdit={this.handleEditDimension}
              onCancel={this.handleCloseModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DimensionList;
