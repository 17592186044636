
// AddEditDimensionForm.js
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import apiService from '../../../services/apiServices';

class AddEditDimensionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.dimension ? props.dimension.name : '',
      description: props.dimension ? props.dimension.description : ''
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, description } = this.state;
    try {
      if (this.props.dimension) {
        // Edit existing dimension
        const updatedDimension = await apiService.put(`/dimensions/${this.props.dimension._id}`, { name, description });
        this.props.onEdit(updatedDimension); // Call the onEdit function passed from the parent component
      } else {
        // Add new dimension
        const newDimension = await apiService.post('/dimensions', { name, description });
        this.props.onAdd(newDimension); // Call the onAdd function passed from the parent component
      }
    } catch (error) {
      console.error('Error submitting dimension:', error);
      // Handle error
    }
    this.props.onCancel(); // Close the modal
  };

  render() {
    const { name, description } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} className="dimension-form"> {/* Add the dimension-form class */}
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" value={name} onChange={this.handleChange} placeholder="Enter name" required />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" rows={3} name="description" value={description} onChange={this.handleChange} placeholder="Enter description" required />
        </Form.Group>
        <Button variant="primary" type="submit">{this.props.dimension ? 'Update Dimension' : 'Add Dimension'}</Button>
        <Button variant="secondary" onClick={this.props.onCancel}>Cancel</Button>
      </Form>
    );
  }
}

export default AddEditDimensionForm;
