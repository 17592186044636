import Header from "./header";
import FooterUI from "./footer";

const Layout = ({ children }) => {

  return (
    <>
      <Header />
        <main className="flex-shrink-0 mt-5" >{children}</main>
      <FooterUI />
    </>
  );
};

export default Layout;
