import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import apiService from "../../../services/apiServices";

class AddEditTopicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.topic ? props.topic.name : "",
      dimension: props.topic ? props.topic.dimension : "",
      dimensions: [], // Array to hold dimension options
    };
  }

  componentDidMount() {
    this.fetchDimensions();
    console.log(this.state.dimension)
  }

  fetchDimensions = async () => {
    try {
      const dimensions = await apiService.get("/dimensions"); // Fetch dimensions from API
      this.setState({ dimensions });
    } catch (error) {
      console.error("Error fetching dimensions:", error);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, dimension } = this.state;
    try {
      if (this.props.topic) {
        // Edit existing topic
        await apiService.put(`/topics/${this.props.topic._id}`, {
          name,
          dimension,
        });
        this.props.onEdit(); // Call the onEdit function passed from the parent component
      } else {
        // Add new topic
        await apiService.post("/topics", { name, dimension });
        this.props.onAdd(); // Call the onAdd function passed from the parent component
      }
    } catch (error) {
      console.error("Error submitting topic:", error);
      // Handle error
    }
    this.props.onCancel(); // Close the modal
  };

  render() {
    const { name, dimension, dimensions } = this.state;
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
            <FormLabel>Name</FormLabel>
            <FormControl
              type="text"
              name="name"
              value={name}
              onChange={this.handleChange}
              placeholder="Enter name"
              required
            />
          </FormGroup>
          <FormGroup controlId="dimension">
            <FormLabel>Dimension</FormLabel>
            <FormControl
              as="select"
              name="dimension"
              value={dimension}
              onChange={this.handleChange}
              required
            >
              <option value="">Select dimension</option>
              {dimensions.map((dim) => (
                <option key={dim._id} value={dim._id}>
                  {dim.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>
          <div className="m-3 align-items-center">
            <Button variant="primary m-2"  type="submit">
              {this.props.topic ? "Update Topic" : "Add Topic"}
            </Button>
            <Button variant="secondary m-2" onClick={this.props.onCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

export default AddEditTopicForm;
