import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/images/logo.png";
import "../../assets/scss/header.css";
import {Button} from "primereact/button";

import UserPanel from './userpanel';
import apiService from "../../services/apiServices";

class Header extends Component {
  state = {
    user: {
      firstname: "",
      lastname: "",
      role: "",
    },
    showUserPanel: false,
  };

  async componentDidMount() {
    try {
      const response = await apiService.get("/users/me");
      // Assuming the API returns user data directly
      this.setState({ user: response });
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      // Handle errors or fallback scenarios (e.g., redirect to login)
    }
  }
  toggleUserPanel = () => {
    this.setState(prevState => ({
      showUserPanel: !prevState.showUserPanel
    }));
  };


  render() {
    const { user, showUserPanel } = this.state;
    const blurClass = showUserPanel ? 'is-blurred' : '';

    return (
      <div className={`main-content ${blurClass}`}>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <img
              src={logo}
              alt="Logo"
              width="100"
              height="100"
              className="d-inline-block align-top"
            />
            <Link className="navbar-brand" to="/">
              Global Assurance Dashboard
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">
                  Dashboard
                </Link>
              </li>
              {/* Conditional rendering based on user.role */}
              {(user.role === "admin" || user.role === "focalPoint") && (
                <li className="nav-item">
                  <Link className="nav-link" to="/data">
                    Data
                  </Link>
                </li>
              )}
              {user.role === "admin" && (
                <li className="nav-item">
                  <Link className="nav-link" to="/settings">
                    Settings
                  </Link>
                </li>
              )}
            </ul>
            {/* Navbar content */}
            <ul className="nav mr-5">
              <li className="nav-item dropdown justify-content-center align-items-center">
                <Button
                  className="nav-link"
                  id="navbarDropdown"
                  style={{ color: "#ffffff" }}
                  role="button"
                  onClick={this.toggleUserPanel}
                >
                  <Avatar
                    label={`${user.firstname[0]}${user.lastname[0]}`}
                    className="mr-2"
                    size="large"
                    shape="circle"
                    style={{ backgroundColor: "#009688", color: "#ffffff", marginRight: "10px"}}
                  />
                  {`${user.firstname} ${user.lastname}`}
                </Button>
              </li>
            </ul>
          </div>
        </nav>
        <UserPanel visible={this.state.showUserPanel} onHide={this.toggleUserPanel} user={this.state.user} />

      </div>
    );
  }
}

export default Header;
