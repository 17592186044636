import React from "react";
import { ToastContainer } from "react-toastify";
import Layout from "../components/layouts/layout";
import LevelOfFunctionalProcesses from "../components/dashboard/LevelOfFunctionalProcesses";
import DimensionPieCharts from "../components/dashboard/ScoreByFunctionalArea";
import DimensionComments from "../components/dashboard/DimensionComments";
import KPIOverviewCard from "../components/dashboard/KPIOverviewCard";




class Home extends React.Component {

  render() {
    return (
      <>
        <Layout>
          <div className="container">
            <h1 className="my-4 text-center">
              RBD High-Risk Countries Assurance Dashboard
            </h1>

            <div className="row fixed-height-row">
              <div className="col-lg-3 mb-4 full-height">
                <div className="card shadow">
                  <div className="card-body">
                    <i className="fa fa-map" aria-hidden="true"></i>
                    <KPIOverviewCard />
                  </div>
                </div>
              </div>
              <div className="col-lg-9 mb-4 full-height">
                <div className="card shadow">
                  <div className="card-body">
                    <h4 className="card-title chart-title">
                      Level of functional processes & recommendations
                      implemented
                    </h4>
                    <LevelOfFunctionalProcesses />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 full-height">
                <div className="card shadow">
                  <div className="card-body">
                    <h4 className="card-title">Score by Functional Area</h4>
                    <DimensionPieCharts />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <div className="card shadow">
                  <div className="card-body">
                    <h4 className="card-title">Dimension Comments</h4>
                    <DimensionComments/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
        <ToastContainer />
      </>
    );
  }
}

export default (Home);
